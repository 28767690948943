<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              v-for="breadcrumb in breadcrumbs"
              :to="breadcrumb.to"
              :key="breadcrumb.name"
            >{{ breadcrumb.name }}</b-breadcrumb-item>

            <b-breadcrumb-item active>
              {{ title }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">{{ title }}</h4>
        </div>
      </div>

      <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
        <section class="row mb-8">
          <div class="col-12 col-xl-8">
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="通路名稱"
              label-for="name"
            >
              <b-form-input
                id="name"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                v-model="state.form.name"
                :state="v$.form.name.$error ? false : null"
                :disabled="isShow"
              ></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.name.$error">此欄位為必填</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="通路類型"
              label-for="type"
            >
              <b-form-select
                id="type"
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="state.form.type"
                :state="v$.form.type.$error ? false : null"
                :options="merchantTypes"
                :disabled="isShow"
              ></b-form-select>
              <b-form-invalid-feedback :state="!v$.form.type.$error">此欄位為必填</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="通路簡稱"
              label-for="type-name"
            >
              <b-form-input
                id="type-name"
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="state.form.type_name"
                :state="v$.form.type_name.$error ? false : null"
                :disabled="isShow"
              ></b-form-input>
              <b-form-invalid-feedback :state="!v$.form.type_name.$error">此欄位為必填</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="是否啟用"
              label-for="is_enabled"
            >
              <b-form-checkbox
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="state.form.is_enabled"
                name="is_enabled"
                :value="1"
                :unchecked-value="0"
                :disabled="isShow"
              ></b-form-checkbox>
            </b-form-group>

            <b-form-group
              v-if="(isShow || isEdit) && isLine"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="立即同步"
            >
              <b-button
                v-b-tooltip.hover
                title="設定 LINE Messaging API Token"
                class="m-2"
                @click="updateChannelToken"
              >
                設定 LINE Messaging API Token
              </b-button>
              <b-button
                v-b-tooltip.hover
                title="更新 OA"
                class="m-2"
                @click="updateOaOnly"
              >
                更新 OA
              </b-button>
              <b-button
                v-b-tooltip.hover
                title="重設組織 LINE 參數"
                class="m-2"
                @click="updateOrgVariables"
                v-if="hasOa"
              >
              重設組織 LINE 參數
              </b-button>
              <br>
              <b-button
                v-b-tooltip.hover
                title="更新好友"
                class="m-2"
                @click="updateFriends"
              >
                更新好友
              </b-button>
              <b-button
                v-b-tooltip.hover
                title="建立組織 LIFF"
                class="m-2"
                @click="updateOrCreateLineLiff"
              >
                建立組織 LIFF
              </b-button>
              <b-button
                v-b-tooltip.hover
                title="設定組織 Webhook"
                class="m-2"
                @click="updateLineWebhook"
              >
                設定組織 Webhook
              </b-button>
            </b-form-group>

            <b-form-group
              v-if="(isShow || isEdit) && isLine"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="Webhook 網址"
            >
              <b-form-input
                class="mb-2 mr-sm-2"
                type="text"
                :disabled="true"
                :value="`${webhookHost}/line/${merchantId}`"
              ></b-form-input>
              <b-form-input
                class="mb-2 mr-sm-2 mb-sm-0"
                type="text"
                :disabled="true"
                :value="`${webhookHost}/cresclab/${merchantId}`"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              v-if="(isShow || isEdit) && isLine"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="LIFF Endpoint URL"
            >
              <b-form-input
                class="mb-2 mr-sm-2"
                type="text"
                :disabled="true"
                :value="`${host}/${organization.code}/liff/`"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              v-if="(isShow || isEdit) && isLine"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="LINE OA ID"
            >
              <b-form-input
                class="mb-2 mr-sm-2"
                type="text"
                :disabled="true"
                :value="lineOaId ? `@${lineOaId}` : '未設定'"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              v-if="(isShow || isEdit) && isLine"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="LINE OA URL"
            >
              <b-form-input
                class="mb-2 mr-sm-2"
                type="text"
                :disabled="true"
                :value="lineOaId ? `https://line.me/R/ti/p/@${lineOaId}` : '未設定'"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              v-if="(isShow || isEdit) && isLine"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="LINE LIFF 網址"
            >
              <b-form-input
                class="mb-2 mr-sm-2"
                type="text"
                :disabled="true"
                :value="lineLiffId ? `https://liff.line.me/${lineLiffId}/` : '未設定'"
              ></b-form-input>
            </b-form-group>
          </div>
        </section>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <b-button class="mr-4" variant="outline-danger" @click="goListPage">取消</b-button>
        <b-overlay
          v-if="!isShow"
          :show="isSubmmiting"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
        >
          <b-button variant="success" @click="handleSubmit">
            {{
              isEdit ? "確認修改" : "確認新增"
            }}
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import { reactive } from "@vue/composition-api";
import { mapState, mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import merchantApi from "@/apis/merchant";
import organizationVariableApi from "@/apis/organization-variable";
import orgNameMixin from "@/mixins/organizationName"
// import * as consts from "@/consts";

export default {
  mixins: [orgNameMixin],
  components: {
  },
  setup() {
    const state = reactive({
      form: {
        name: "",
        type: "",
        type_name: "",
        is_enabled: 0
      },
    });
    const rules = {
      form: {
        name: { required },
        type: { required },
        type_name: { required },
      },
    };

    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      isLoading: false,
      isSubmmiting: false,
      form: {
        name: "",
        type: "",
        type_name: "",
        is_enabled: 0
      },
      hasOa: false,
      merchantTypes: [],
      lineOaId: "",
      lineLiffId: "",
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        type: { required },
        type_name: { required },
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters("route", ["routeQuery", "routeParams"]),
    // 是從「全系統管理」頁面進入修改
    isSystemSetting() {
      return ['OrgMerchantEdit', 'OrgMerchantCreate'].includes(this.$route.name)
    },
    breadcrumbs() {
      if (this.isSystemSetting) {
        return [
          { to: { name: 'SystemSettingsHome' }, name: '全系統管理' },
          { to: { name: 'OrganizationList' }, name: '組織管理' },
          { to: { name: 'OrgMerchantList' }, name: '通路管理' },
        ]
      }

      return [
        { to: { name: 'OrganizationHome' }, name: '組織資料設定' },
      ]
    },
    orgId() {
      if (this.isSystemSetting) {
        return this.$route.params.orgId;
      }
      return this.$store.state.general.organization.id;
    },
    merchantId() {
      if (this.isSystemSetting) {
        return this.$route.params.merchantId;
      }
      return this.$route.params.merchant_id;
    },
    isShow() {
      return ["MerchantShow"].includes(this.$route.name);
    },
    isEdit() {
      return ["OrgMerchantEdit", "MerchantEdit"].includes(this.$route.name);
    },
    title() {
      switch (true) {
        case this.isShow:
          return `${this.organizationName}查看通路`;
        case this.isEdit:
          return `${this.organizationName}編輯通路`;
        default:
          return `${this.organizationName}新增通路`;
      }
    },
    webhookHost() {
      return process.env.VUE_APP_WEBHOOK_HOST
    },
    host() {
      return window.location.origin
    },
    isLine() {
      return this.state.form.type === 'LINE'
    },
  },
  mounted() {
    if (!this.hasPermission()) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33"
        })
        .then(() => {
          this.goListPage()
        });
    } else if (this.merchantId) {
      this.fetchMerchant();
    }
    this.getMerchantTypes();
  },
  methods: {
    async getMerchantTypes() {
      const { data } = await merchantApi.getMerchantTypes(this.orgId)
      this.merchantTypes = Object.entries(data.types).filter((type) => {
        return type[0].includes('TYPE')
      }).map((type) => ({
        text: type[0].replace('TYPE_', ''),
        value: type[1]
      }));
    },
    hasPermission() {
      return this.$permissions.has(this.$permissions.consts.MERCHANT_MODIFY)
        || (this.isShow && this.$permissions.has(this.$permissions.consts.MERCHANT_LIST_VIEW))
    },
    async fetchMerchant() {
      try {
        this.isLoading = true;
        const { data } = await merchantApi.getMerchant(this.orgId, this.merchantId);
        const { name, type, type_name, is_enabled } = data.data;

        if (this.isEdit || this.isShow) {
          this.form = {
            ...this.form,
          };
          this.state.form = {
            name,
            type,
            type_name,
            is_enabled
          };
        }

        this.hasOa = !!data.data.line_oa

        if (this.state.form.type === 'LINE') {
          await this.fetchLineOrgVariable();
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async fetchLineOrgVariable() {
      try {
        this.isLoading = true;
        const { data } = await organizationVariableApi.getByKey({ org_code: this.orgId, key: 'LINE_OA_ID' });
        this.lineOaId = data.data.value;
        const { data: response } = await organizationVariableApi.getByKey({ org_code: this.orgId, key: 'LINE_PORTAL_LIFF_ID' });
        this.lineLiffId = response.data.value;
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;
      if (this.isEdit) {
        this.handleUpdate();
      } else {
        this.handleCreate();
      }
    },
    async handleUpdate() {
      try {
        this.isSubmmiting = true;
        await merchantApi.updateMerchant(this.orgId, this.merchantId, {
          ...this.form,
          ...this.state.form,
        });
        this.showSuccessPopup();
      } catch (error) {
        const { status, data } = error.response;
        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      }
      this.isSubmmiting = false;
    },
    async handleCreate() {
      try {
        this.isSubmmiting = true;
        await merchantApi.createMerchant(this.orgId, {
          ...this.form,
          ...this.state.form,
        });
        this.showSuccessPopup();
      } catch (error) {
        const { status, data } = error.response;
        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      }
      this.isSubmmiting = false;
    },
    goListPage() {
      if (this.isSystemSetting) {
        if (this.routeParams.orgId) {
          this.$router.push({
            name: "OrgMerchantList",
            params: this.routeParams,
            query: this.routeQuery,
          });
        } else {
          this.$router.push({
            name: "OrgMerchantList",
            params:{ orgId: this.orgId }
          });
        }
      } else {
        this.$router.push({ name: "OrganizationHome" });
      }
    },
    showSuccessPopup() {
      this.$swal
        .fire({
          type: "success",
          text: this.isEdit ? "修改成功" : "新增成功",
        })
        .then(() => {
          this.goListPage()
        });
    },
    async updateOaOnly() {
      this.isLoading = true;

      try {
        const { data } = await merchantApi.updateOaOnly(this.merchantId);
        if (data.status === "success") {
          this.$swal.fire({
            title: "同步 Line OA 成功",
            type: "success",
          });
          this.fetchMerchant();
        } else {
          this.$swal.fire({
            title: "同步 Line OA 失敗",
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "同步 Line OA 失敗",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async updateFriends() {
      this.isLoading = true;

      try {
        const { data } = await merchantApi.updateFriends(this.merchantId);
        if (data.status === "success") {
          this.$swal.fire({
            title: "同步好友成功",
            type: "success",
          });
        } else {
          this.$swal.fire({
            title: "同步好友失敗",
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "同步好友失敗",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async updateOrgVariables() {
      let confirm = await this.$swal.fire({
        text: "執行後將會重新設定 該組織 LINE 相關的設定檔，確定執行嗎？",
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        showCancelButton: true,
        // showCloseButton: true,
      });

      if (!confirm.value) {
        return
      }

      this.isLoading = true;

      try {
        const { data } = await merchantApi.updateOrgVariables(this.merchantId);
        if (data.status === "success") {
          this.$swal.fire({
            title: "同步組織參數成功",
            type: "success",
          });
          this.fetchMerchant()
        } else {
          this.$swal.fire({
            title: "同步組織參數失敗",
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "同步組織參數失敗",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async updateLineWebhook() {
      this.isLoading = true;

      try {
        const { data } = await merchantApi.updateLineWebhook(this.merchantId);
        if (data.status === "success") {
          this.$swal.fire({
            title: "同步 Webhook 成功",
            type: "success",
          });
        } else {
          console.log(data.data.message);
          this.$swal.fire({
            title: data.data && data.data.message ? data.data.message : "同步 Webhook 失敗",
            type: "error",
          });

          console.error(data);
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "同步 Webhook 失敗",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async updateOrCreateLineLiff() {
      this.isLoading = true;

      try {
        const { data } = await merchantApi.updateOrCreateLineLiff(this.merchantId);
        if (data.code == 201) {
          this.$swal.fire({
            title: "成功更新 LINE Liff",
            type: "success",
            html: "LIFF 建立完成，<br>短網址建立失敗！",
          });
          this.fetchMerchant()
        } else if (data.status === "success") {
          this.$swal.fire({
            title: "成功更新 LINE Liff",
            type: "success",
          });
          this.fetchMerchant()
        } else {
          // 特殊例外，代表有填寫過 Liff ID ，但是沒有成功
          if (data.status === "failed" && data.code === 404) {
              this.$swal.fire({
                title: "更新 LINE Liff 失敗",
                type: "error",
                showCancelButton: true,
                confirmButtonText: "確定重建",
                cancelButtonText: "取消",
                "html": `LIFF ID ${this.lineLiffId} 不存在 LINE，請問要重新建立一個新的 LIFF 嗎？`,
              }).then(async (result) => {
                if (result.value) {
                  const { data } = await merchantApi.updateOrCreateLineLiff(this.merchantId, true);
                  console.log("", data)
                  if (data.status === "success") {
                    this.$swal.fire({
                      title: "成功更新 LINE Liff",
                      type: "success",
                    });
                    this.fetchMerchant()
                  } else {
                    const html = Object.values(data.message)
                      .map((m) => m[0])
                      .join("");
                    this.$swal.fire({
                      title: "更新 LINE Liff 失敗",
                      type: "error",
                      html,
                    });
                  }
                }
              });
          } else if (data.message) {
            const html = Object.values(data.message)
              .map((m) => m[0])
              .join("");
            this.$swal.fire({
            title: "更新 LINE Liff 失敗",
              type: "error",
              html,
            });
          }
        }
      } catch (error) {
        const { data } = error.response;
        if (data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("");
          this.$swal.fire({
          title: "更新 LINE Liff 失敗",
            type: "error",
            html,
          });
        }
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async updateChannelToken() {
      this.isLoading = true;

      try {
        const { data } = await merchantApi.updateChannelToken(this.merchantId);
        if (data.status === "success") {
          this.$swal.fire({
            title: "成功更新 LINE Messaging API Token",
            type: "success",
          });
        } else {
          this.$swal.fire({
            title: "更新  LINE Messaging API Token 失敗",
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "更新 LINE Messaging API Token 失敗",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
